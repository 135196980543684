import React from 'react';
import {
  mdiFormatBold,
  mdiFormatUnderline,
  mdiFormatItalic,
  mdiFormatStrikethrough,
  mdiFormatAlignLeft,
  mdiFormatAlignCenter,
  mdiFormatAlignRight,
  mdiFormatAlignJustify,
  mdiFormatListNumbered,
  mdiFormatListBulleted,
  mdiTable,
  mdiImageOutline,
  mdiFormatColorText,
  mdiAnchor,
} from '@mdi/js';
import { Button } from 'tabler-react';
import Icon from '@mdi/react';
import styled from 'styled-components';
import './Editor.css';
import { Tooltip } from 'react-tippy';
import EditorImage from './EditorImage';
import EditorColor from './EditorColor';
import Switch from 'react-switch';
import { UserContext } from '../../../context/UserContext';
import CodeMirror from 'react-codemirror2';
import 'codemirror/mode/htmlmixed/htmlmixed';
import htmlBeautify from 'html-beautify';
import EditorTable from './EditorTable';
import EditorAnchorLink from './EditorAnchorLink';

const StyledContent = styled.div`
  margin-top: 10px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 5px;
  min-height: 200px;
  outline: none;
  padding: 15px;
  line-height: 2;
  color: black;

  ol > li {
    padding-left: 5px;
  }

  div[contenteditable='false'] {
    position: relative;

    .not-editable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #495057;
      opacity: 0.7;
      border: 3px solid #343a40;
      z-index: 1;
    }

    .info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-weight: 600;
      padding: 15px;
      border-radius: 5px;
      background: white;
    }
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin-right: 5px;
  }
`;

const StyledSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const StyledEditorWrapper = styled.div`
  display: ${({ isAdvancedMode }) => (isAdvancedMode && 'none') || 'block'};
`;

const formats = [
  {
    name: 'bold',
    icon: mdiFormatBold,
    title: 'Pogrubienie',
  },
  {
    name: 'underline',
    icon: mdiFormatUnderline,
    title: 'Podkreślenie',
  },
  {
    name: 'italic',
    icon: mdiFormatItalic,
    title: 'Pochylenie',
  },
  {
    name: 'strikeThrough',
    icon: mdiFormatStrikethrough,
    title: 'Przekreślenie',
  },
  {
    name: 'justifyLeft',
    icon: mdiFormatAlignLeft,
    title: 'Wyrównanie do lewej',
  },
  {
    name: 'justifyCenter',
    icon: mdiFormatAlignCenter,
    title: 'Wyrównanie do środka',
  },
  {
    name: 'justifyRight',
    icon: mdiFormatAlignRight,
    title: 'Wyrównanie do prawej',
  },
  {
    name: 'justifyFull',
    icon: mdiFormatAlignJustify,
    title: 'Wyjustowanie',
  },
  {
    name: 'insertOrderedList',
    icon: mdiFormatListNumbered,
    title: 'Lista numerowana',
  },
  {
    name: 'insertUnorderedList',
    icon: mdiFormatListBulleted,
    title: 'Lista punktowa',
  },
  {
    name: 'insertTable',
    icon: mdiTable,
    title: 'Tabela',
    component: EditorTable,
    // handler: () => {
    //   const selectedNode = document.getSelection().anchorNode;
    //   const node = document.createElement("div");
    //   node.innerHTML =
    //     "<table><tr><td>1</td><td>2</td></tr><tr><td>3</td><td>4</td></tr></table>";

    //   selectedNode.appendChild(node);
    // }
  },
  {
    name: 'insertImage',
    icon: mdiImageOutline,
    title: 'Wstaw zdjęcie',
    component: EditorImage,
  },
  {
    name: 'color',
    icon: mdiFormatColorText,
    title: 'Kolor tekstu',
    component: EditorColor,
  },
  {
    name: 'insertLink',
    icon: mdiAnchor,
    title: 'Link',
    component: EditorAnchorLink,
  },
];

const deafultSettings = {
  formats: [
    'bold',
    'underline',
    'italic',
    'strikeThrough',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'insertOrderedList',
    'insertUnorderedList',
    'insertTable',
    'insertImage',
    'color',
    'insertLink',
  ],
};

const Editor = ({
  content = '<div></div>',
  onChange,
  settings = deafultSettings,
}) => {
  const [isAdvancedMode, setIsAdvancedMode] = React.useState(false);
  const [currentHtml, setCurrentHtml] = React.useState(content);

  const { user } = React.useContext(UserContext);
  const contentRef = React.useRef(null);
  const tempRef = React.useRef(null);

  React.useEffect(() => {
    if (content) {
      contentRef.current.innerHTML = content;
      setTablesNotEditable();
    }
  }, []);

  const setTablesNotEditable = () => {
    contentRef.current.querySelectorAll("[id^='TABLE']").forEach((x) => {
      x.setAttribute('contenteditable', false);
      const id = x.getAttribute('id');
      if (!document.querySelector("[data-table='" + id + "']")) {
        const notEdtableCover = document.createElement('div');
        notEdtableCover.classList.add('not-editable');
        notEdtableCover.dataset.table = id;
        x.append(notEdtableCover);

        const info = document.createElement('div');
        info.classList.add('info');
        info.dataset.table = id;
        info.innerHTML = `Zawartość tabeli edytujemy w zakładce tabele: <a href="/tabele?key=${id}">Kliknij</a>, aby przejść do edycji.`;
        x.append(info);
      }
      // if (!document.querySelector("[data-table='" + id + "']")) {
      //   const info = tableInfoRef.current.cloneNode(true);
      //   info.dataset.table = id;
      //   info.innerHTML = `Zawartość tabeli edytujemy w zakładce tabele: <a href="/tabele?key=${id}" target="_blank">Kliknij</a>, aby przejść do edycji.`;
      //   setTimeout(() => {
      //     console.log(x.getBoundingClientRect());
      //   }, 500);
      //   wrapperRef.current.append(info);
      // }
    });
  };

  const handleStandardFormat = (format) => {
    document.execCommand(format, false, '');
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    document.execCommand(
      'inserttext',
      false,
      e.clipboardData.getData('text/plain')
    );
  };

  const emitChange = () => {
    const html = getFinalHtml();
    onChange(html);
    setTablesNotEditable();
  };

  const getFinalHtml = () => {
    tempRef.current.innerHTML = contentRef.current.innerHTML;
    tempRef.current.querySelectorAll("[data-table^='TABLE']").forEach((x) => {
      x.remove();
    });
    tempRef.current
      .querySelectorAll("[contenteditable='false']")
      .forEach((x) => {
        x.removeAttribute('contenteditable');
      });

    return tempRef.current.innerHTML;
  };

  const handleOnModeChange = () => {
    if (isAdvancedMode === true) {
      contentRef.current.innerHTML = currentHtml;
    } else {
      setCurrentHtml(htmlBeautify(contentRef.current.innerHTML));
    }

    setIsAdvancedMode(!isAdvancedMode);
  };

  return (
    <div className="editor" style={{ width: '100%' }}>
      <StyledEditorWrapper isAdvancedMode={isAdvancedMode}>
        <StyledButtonsWrapper>
          {formats.map(
            ({ title, name, icon, handler, component: Component }, i) => {
              if (settings.formats.includes(name)) {
                return (
                  <Tooltip key={i} title={title} position="bottom" arrow>
                    {Component ? (
                      <Component onEnd={emitChange} icon={icon} />
                    ) : (
                      <Button
                        color="secondary"
                        type="button"
                        className="only-icon"
                        onClick={() =>
                          handler ? handler() : handleStandardFormat(name)
                        }
                      >
                        <Icon path={icon} size={0.8} color="#222222" />
                      </Button>
                    )}
                  </Tooltip>
                );
              }
            }
          )}
        </StyledButtonsWrapper>
        <StyledContent
          ref={contentRef}
          contentEditable
          onPaste={handleOnPaste}
          onInput={emitChange}
          onBlur={emitChange}
        ></StyledContent>
      </StyledEditorWrapper>
      {user.role === 'Administrator' && (
        <StyledSwitcher>
          <Switch
            onChange={handleOnModeChange}
            checked={isAdvancedMode}
          ></Switch>
        </StyledSwitcher>
      )}
      {isAdvancedMode && (
        <CodeMirror
          value={currentHtml}
          onChange={(value) => {
            setCurrentHtml(value);
            onChange(value);
          }}
          options={{ lineNumbers: true, mode: 'htmlmixed', htmlMode: true }}
        />
      )}
      <div ref={tempRef} style={{ display: 'none' }}></div>
    </div>
  );
};

export default Editor;
