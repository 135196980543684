import React, { useState, useEffect } from 'react';
import SiteWrapper from '../layout/SiteWrapper';
import PageContent from '../layout/PageContent';
import { Grid, Button, Table, Card } from 'tabler-react';
import Loader from '../layout/Loader';
import handleError from '../../utils/errorHandler';
import PageHeader from '../layout/PageHeader';
import Icon from '@mdi/react';
import { mdiTrashCanOutline, mdiFolderAccountOutline } from '@mdi/js';
import styled from 'styled-components';
import ConfirmModal from '../modal/ConfirmModal';
import ListNoDataMessage from '../shared/ListNoDataMessage';
import moment from 'moment';
import EnrolmentsService from '../../services/enrolmentsService';
import { Tooltip } from 'react-tippy';
import Pager from '../pager/Pager';

const StyledButtonsCol = styled(Table.Col)`
  width: 75px;

  > div {
    text-align: center;
  }
`;

const limit = 6;

const Enrolments = ({ history }) => {
  const [enrolments, setEnrolments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    isOpen: false,
    message: '',
  });
  const [pagingInfo, setPagingInfo] = useState(null);
  const getEnrolments = async (page) => {
    const enrolments = await EnrolmentsService.getAll(page, limit);
    return enrolments;
  };

  const fetchEnrolments = async (page = 1) => {
    setIsLoading(true);
    try {
      const enrolments = await getEnrolments(page);

      setEnrolments(enrolments.items);
      setPagingInfo({
        totalCount: enrolments.count,
        currentPage: page,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, history);
    }
  };

  useEffect(() => {
    fetchEnrolments();
  }, []);

  const handleOnChangePage = (page) => {
    fetchEnrolments(page);
  };

  const deleteEnrolment = async (id) => {
    setConfirmModalOptions({ isOpen: false });
    setIsLoading(true);

    try {
      await EnrolmentsService.delete(id);

      fetchEnrolments();
    } catch (error) {
      handleError(error, history);
    }
  };

  if (isLoading) {
    return (
      <SiteWrapper>
        <Loader />
      </SiteWrapper>
    );
  }

  return (
    <SiteWrapper>
      <PageContent>
        <PageHeader
          title="Zapisane osoby"
          icon={mdiFolderAccountOutline}
        ></PageHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div
            style={{
              padding: '0 0 0 5px',
            }}
          >
            Znalezionych wpisów: <strong>{pagingInfo.totalCount}</strong>
          </div>
          {enrolments.length > 0 && (
            <div style={{ marginLeft: 'auto' }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
        <Grid.Row>
          <Grid.Col lg={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Nazwisko i imię</Table.ColHeader>
                    <Table.ColHeader>Kierunek</Table.ColHeader>
                    <Table.ColHeader>Email</Table.ColHeader>
                    <Table.ColHeader>PESEL / Numer dowodu</Table.ColHeader>
                    <Table.ColHeader>Numer telefonu</Table.ColHeader>
                    <Table.ColHeader>Ulica</Table.ColHeader>
                    <Table.ColHeader>Kod pocztowy</Table.ColHeader>
                    <Table.ColHeader>Miasto</Table.ColHeader>
                    <Table.ColHeader>Województwo</Table.ColHeader>
                    <Table.ColHeader>Kraj</Table.ColHeader>
                    <Table.ColHeader>Data zapisu</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {enrolments.map((enrolment) => {
                    return (
                      <Table.Row key={enrolment.id}>
                        <Table.Col>
                          {enrolment.lastName} {enrolment.firstName}
                        </Table.Col>
                        <Table.Col>
                          {enrolment.courseSymbol} - {enrolment.course}
                        </Table.Col>
                        <Table.Col>{enrolment.email}</Table.Col>
                        <Table.Col>
                          {enrolment.pesel}
                          {enrolment.idNumber}
                        </Table.Col>
                        <Table.Col>{enrolment.phoneNumber}</Table.Col>
                        <Table.Col>{enrolment.street}</Table.Col>
                        <Table.Col>{enrolment.postCode}</Table.Col>
                        <Table.Col>{enrolment.city}</Table.Col>
                        <Table.Col>{enrolment.region}</Table.Col>
                        <Table.Col>{enrolment.country}</Table.Col>
                        <Table.Col>
                          {moment(enrolment.createdDate).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Table.Col>
                        <StyledButtonsCol>
                          <div>
                            <Tooltip title="Usuń" position="bottom" arrow>
                              <Button
                                color="danger"
                                type="button"
                                className="only-icon"
                                onClick={() =>
                                  setConfirmModalOptions({
                                    isOpen: true,
                                    message: `Czy na pewno chcesz usunąć zapis osoby: <strong>${enrolment.lastName} ${enrolment.firstName}</strong>?`,
                                    state: enrolment.id,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiTrashCanOutline}
                                  size={0.8}
                                  color="white"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        </StyledButtonsCol>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {enrolments.length === 0 && <ListNoDataMessage />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          {enrolments.length > 0 && (
            <div style={{ marginLeft: 'auto' }}>
              <Pager
                onClick={handleOnChangePage}
                totalCount={pagingInfo.totalCount}
                currentPage={pagingInfo.currentPage}
                limit={limit}
              />
            </div>
          )}
        </div>
      </PageContent>
      <ConfirmModal
        isOpen={confirmModalOptions.isOpen}
        message={confirmModalOptions.message}
        state={confirmModalOptions.state}
        onConfirm={deleteEnrolment}
        onDiscard={() => setConfirmModalOptions({ isOpen: false })}
      ></ConfirmModal>
    </SiteWrapper>
  );
};

export default Enrolments;
