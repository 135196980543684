import Axios from 'axios';

const EnrolmentsService = {
  getAll: async (page, limit) => {
    const result = await Axios.get(
      `/api/enrolments?page=${page}&limit=${limit}`
    );

    return result.data;
  },
  delete: async (id) => {
    await Axios.delete(`/api/enrolments/${id}`);
  },
};

export default EnrolmentsService;
